import React, { Component } from 'react';
import { css } from "@emotion/core";
import { Link } from "gatsby";
import {sortableHandle} from 'react-sortable-hoc';
import { Offline, Online } from 'react-detect-offline';

var numOfPortfolios = 0;
var numOfActive = 0;
var numOfArchived=0;

function countPortfolios() {
  numOfPortfolios = numOfPortfolios+1;
};

const DragHandle = sortableHandle(() => <div className="drag" draggable>
<span className="dragIcon"></span>
</div>);

function accordionExpand(e) {
  var acc = document.getElementById(e.currentTarget.id);
  var accordionButtons = document.getElementsByClassName('accordion');
  var panel = acc.nextElementSibling.nextElementSibling.nextElementSibling;
  for (var i = 0; i < accordionButtons.length; i++) {
    var aPanel = accordionButtons[i].nextElementSibling.nextElementSibling.nextElementSibling;
    if (accordionButtons[i] == acc) {
      //do nothing
    } else {
      accordionButtons[i].classList.remove("active");
      aPanel.style.maxHeight = null;
    }
  }
  acc.classList.toggle("active");
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
  }
  
}

function portfolioFrame(e) {
  //var sourceChange = e.currentTarget.getAttribute('data-source');
  var reactModal = document.getElementsByClassName('ReactModalPortal');
  var portfolioNameOverlay = document.getElementById('portfolioNameOverlay');
  var pName = e.currentTarget.getAttribute('data-pname');
  //var portfolioIframe = document.getElementById('portfolioIframe');
  //var portfolioIframeClose = document.getElementById('portfolioIframeClose');
  //portfolioIframe.src = sourceChange;
  //portfolioIframe.setAttribute("style", "display:block;");
  //portfolioIframeClose.setAttribute("style", "display:block;");
  portfolioNameOverlay.setAttribute("style", "display:block;");
  portfolioNameOverlay.innerHTML = pName;
}


class MessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.collection.text,
      archived: this.props.collection.archived
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.collection.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditCollection(this.props.collection, this.state.editText);

    this.setState({ editMode: false });
  };

  

  unarchiveConfirm(e) {
    var cUnPopup = e.currentTarget.getAttribute('data-item');
    var hPopupUnArchive = document.getElementsByClassName('unarchiveConfirmPopup');
    var hPopupUnDelete = document.getElementsByClassName('undeleteConfirmPopup');
    var pUnConfirm = document.getElementById(cUnPopup);
    //var pHide = document.getElementById(hPopup);
    for (var i = 0; i < hPopupUnArchive.length; ++i) {
        var item = hPopupUnArchive[i];
        if (item.id !== cUnPopup) {
          item.classList.remove('active');
        }
    }
    for (var i = 0; i < hPopupUnDelete.length; ++i) {
      var itemd = hPopupUnDelete[i];
      if (itemd.id !== cUnPopup) {
        itemd.classList.remove('active');
      }
    }
    if (pUnConfirm.classList.contains('active')) {
        pUnConfirm.classList.remove('active');
    } else {
        pUnConfirm.classList.add('active');
    }    
  }

  render() {
    const { authUser, collection, portBrands, onRemoveCollection, onChangeArchiveFalse } = this.props;
    const { editMode, editText } = this.state;  
    return (   
      <React.Fragment>
        {authUser.uid === collection.userId && collection.archived !=false && (
            <React.Fragment>
              <button value='pModal' className="portfolioInfo accordion" id={'accordion'+collection.uid} onClick={accordionExpand}>
                <h3>{collection.portfolioName}</h3>
                <p>{collection.text}</p> 
              </button>

              <div className="portfolioButtons">
                <button className="buttonArchive" data-item={'unarchiveConfirm'+collection.uid} data-hide={'undeleteConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}><span className="icon-Eye"></span></button>
                <Online>
                  <button className="buttonDelete" data-item={'undeleteConfirm'+collection.uid} data-hide={'unarchiveConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}><span className="icon-TrashBin"></span></button>
                </Online>
                <Offline>
                  <button className="buttonDelete disabled"><span className="icon-TrashBin"></span></button>
                </Offline>
              </div>
              <DragHandle />
              <ol className="portfolioProductList">
                {collection.portBrands.map((item, key) => (
                  <li key={key} className={'product-carried-'+key}>
                    {/*<button data-source={item.value} data-pname={collection.portfolioName} onClick={portfolioFrame}>{item.title} {item.label}</button>*/}
                    <Link to={item.value} state={{modal:true}} onClick={portfolioFrame} data-pname={collection.portfolioName}>{item.title} {item.label}</Link>
                  </li>                   
                ))}
              </ol>   
              <div id={'unarchiveConfirm'+collection.uid} className="unarchiveConfirmPopup">
                <button className="closeArea" data-item={'unarchiveConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}></button>
                  <div className="inner">
                  <h3>Are you sure you want to activate this portfolio?</h3>
                    <div className="buttons">
                      <button type="button" className="buttonArchive" onClick={() => onChangeArchiveFalse(collection.uid)}>Yes</button>
                      <button data-item={'unarchiveConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>
              <div id={'undeleteConfirm'+collection.uid} className="undeleteConfirmPopup">
                <button className="closeArea" data-item={'undeleteConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}></button>
                  <div className="inner">
                    <h3>Are you sure you want to delete this portfolio?</h3>
                    <span>Once a portfolio is deleted, it cannot be recovered.</span>
                    <div className="buttons">
                      <button type="button" className="buttonDelete" onClick={() => onRemoveCollection(collection.uid)}>Yes</button>
                      <button data-item={'undeleteConfirm'+collection.uid} onClick={e => this.unarchiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>   
            </React.Fragment>
          )}

      </React.Fragment>
    );
  }
}

export default MessageItem;